body {
  margin: 0;
  font-family: 'Poppins', 'Quicksand', 'Abril+Fatface';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Quicksand';
}
